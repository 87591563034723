@import url(https://fonts.googleapis.com/css?family=Roboto+Mono);
body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

* {
  font-family: 'Roboto Mono', monospace;
  /* color: #484848; */
}

.App * {
  color: #484848;
}

.App {
  text-align: center;
  margin-left: 4em;
  margin-right: 4em;
  height: 90vh;
  margin-bottom: 2em;
}

body {
  margin-bottom: 0em;
  padding-bottom: 0em;
  height: 100vh;
}

.App-logo {
  -webkit-animation: App-logo-spin infinite 20s linear;
          animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
  pointer-events: none;
}

.App-header {
  /* background-color: #282c34; */
  background-color: #24D2A9;
  min-height: 100vh;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.divider {
  width: 1px;
  margin: 4em 4em;
  margin-top: 5em;
  background: #24D2A9;
}

.divider-horizontal {
  width: 20em;
  height: 1px;
  margin: 6px 6px;
  background: #24D2A9;
}

.divider-editing {
  margin: auto;
  margin-top: 1em;
  width: 80%;
  text-align: center;
}

.detail-divider {
  height: 1px;
  width: 5em;
  margin: 3px 3px;
  margin-left: 0;
}

.file-message {
  color: #24D2A9;
}

.file-message.secondary {
  color: #ff6b83;
  font-size: .8em;
}

.hint {
  color: #9b9b9b; /* lighter gray text */
  font-size: .75em;
}

.files-list {
  overflow: scroll;
}

@-webkit-keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

.inline * {
  display: inline;
  padding-left: .5em;
  padding-right: .5em;
}

.bottom-padding {
  padding-bottom: .5em;
  margin-bottom: .5em;
}

.top-padding {
  padding-top: 1em;
  margin-top: 2em;
}

.x, .x * {
  color: #ffafc7;
  font-size: 1.4em;
  font-weight: bold;
  padding: .2em;
}

.edit:hover *, .edit:hover {
  color: #ff6b83;
  cursor: pointer;
}

.edit, .edit * {
  color: #ffafc7;
  cursor: pointer;
}

.flexbox {
  display: -webkit-flex;
  display: flex;
  -webkit-flex: 1 1;
          flex: 1 1;
  -webkit-flex-direction: row;
          flex-direction: row;
}

.resources-container {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-flex: .55 1;
          flex: .55 1;
}

.upload-container {
  display: -webkit-flex;
  display: flex;
  -webkit-flex: .45 1;
          flex: .45 1;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-flex-direction: column;
          flex-direction: column;
  margin-left: 4em;
  height: 35em;
  margin-top: 3em;
}

.qr-container {
  display: -webkit-flex;
  display: flex;
  -webkit-flex: .5 1;
          flex: .5 1;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
}

.user-container {
  display: -webkit-flex;
  display: flex;
  -webkit-flex: .5 1;
          flex: .5 1;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
}

/************ RESOURCES ************/
.resources-container {
  text-align: left;
}

.resources-list {
  overflow-y: scroll;
  height: 35em;
  padding-right: 2em;
}

.resources-list::-webkit-scrollbar {
  width: 2px;
  height: 5px;
  background-color: #eee;
}

.resources-list::-webkit-scrollbar-thumb {
  background: rgba(36, 210, 169, .5);
}

.resource-name {
  font-size: 1.2em;
}

.resource-container {
  padding-bottom: 2em;
  pointer-events: auto !important;
}

.detail-item {
  cursor: -webkit-grab;
  cursor: grab;
}

.detail-item * {
  border-top: none;
  border-left: none;
  border-right: none;
}

.detail-item:active {
  cursor: -webkit-grabbing;
  cursor: grabbing;
}

.detail-title {
  font-weight: bold;
}

.detail-text {
  padding-top: -1em;
  padding-bottom: -1em;
}

.detail-image {
  padding-top: 1em;
  padding-bottom: 1em;
  width: 100%;
  cursor: pointer;
}

.detail-html {
  margin-bottom: 1em;
}

.detail-container a {
  color: #0061ff;
}

.detail-container {
  -webkit-flex: 1 1;
          flex: 1 1;
  margin-top: 1em;
}

.detail-container ul {
  margin-left: -1em;
}

#detail-toggle {
  font-size: .9em;
  margin-bottom: 0em;
  color: #acacac;
}

#detail-toggle:hover {
  color: #9b9b9b;
}

.text-detail {
  -webkit-flex-direction: column;
          flex-direction: column;
}

.text-detail * {
  width: 100%;
}

.caption-input {
  margin-bottom: 1em;
  position: static;
  width: 100%;
  border-top: none;
  border-left: none;
  border-right: none;
  background: transparent;
}

/************ NEW RESOURCE FORM ************/
.new-resource-container {
  padding: 1em;
  max-height: 40em;
  overflow: scroll;
  color: #484848;
}

.new-resource-form * {
  -webkit-flex: 1 1;
          flex: 1 1;
}

.new-resource-form > div:not(:last-child) {
  margin-bottom: 2em;
}

.new-resource-form .new-resource-div-inner {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: baseline;
          align-items: baseline;
  -webkit-justify-content: space-around;
          justify-content: space-around;
}

.new-resource-form div div input {
  padding: 1.5em;
  padding-bottom: .5em;
  border: none;
  border-bottom: 1px solid rgba(140,140,140,.2);
  -webkit-justify-content: flex-end;
          justify-content: flex-end;
  outline: none;
  font-size: .8em;
  padding-left: 0;
}

.new-resource-form .button-container {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
}

#resource-name {
  font-size: 1.2em;
  width: 90%;
}

.detail-display-name {
  font-size: 1.2em;
}

.detail-value {
  font-size: .8em;
}

#resource-s3-upload * {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
}

.header-sm {
  font-size: 1.1em;
}

.link-input * {
  color: #0061ff;
  text-decoration: underline;
}

/************ ENLARGED IMAGE ************/
.enlarged-image-container {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
  padding: 5em;
}

/************ SIGN IN ************/
.signin-container {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-flex: 1 1;
          flex: 1 1;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
  text-align: left;
}

.signin-form {
  padding-top: 2em;
  padding-bottom: 2em;
  padding-left: 0em;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
}

.signin-form input {
  padding: 1.5em;
  padding-bottom: .5em;
  border: none;
  border-bottom: 1px solid rgba(140,140,140,.2);
  -webkit-justify-content: flex-start;
          justify-content: flex-start;
  -webkit-align-items: stretch;
          align-items: stretch;
  outline: none;
  font-size: .8em;
  margin-bottom: 1em;
}

.code-input {
  text-align: center;
}

/************ COMPONENTS ************/
.upload-btn-wrapper {
  position: relative;
  overflow: hidden;
  display: inline-block;
}

.btn {
  padding: 1em;
  outline: none;
  border: 1px solid rgba(140,140,140,.2);
  border-radius: 0em;
  width: 10em;
  cursor: pointer;
  -webkit-align-items: center;
          align-items: center;
}

.upload-btn-wrapper input[type=file] {
  position: absolute;
  /* padding: 3em; */
  width: 100%;
  height: 4em;
  left: 0;
  top: 0;
  opacity: 0;
  cursor: pointer;
}

.buttons {
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  width: 100%;
  display: -webkit-flex;
  display: flex;
}

.button {
  padding: 1em;
  outline: none;
  border: 1px solid rgba(140,140,140,.2);
  border-radius: 0em;
  width: 10em;
  cursor: pointer;
}

.button-sm {
  padding: .5em;
  outline: none;
  border: 1px solid rgba(140,140,140,.2);
  border-radius: 0em;
  width: 2em;
  cursor: pointer;
  color: #ff1c60;
  /* font-size: 1em; */
}

.button-padded {
  margin-bottom: 1em;
}

.button-lg {
  padding: .5em;
  outline: none;
  border: 1px solid rgba(140,140,140,.2);
  border-radius: 0em;
  width: 8em;
  cursor: pointer;
  margin-left: .5em;
  margin-right: .5em;
}

.p-sm {
  font-size: .8em;
}

hr {
  border: 0;
  height: 1px;
  background: rgba(140,140,140,.5);
  margin-top: 2em;
  margin-bottom: 2em;
  width: 80%;
}

.Loader {
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
  display: -webkit-flex;
  display: flex;
  -webkit-flex: 1 1;
          flex: 1 1;
  margin-top: 0%;
}

/*************** EDITOR *****************/
.DraftEditor-root, .DraftEditor-editorContainer {
  width: 100%;
  display: block;
  min-height: 20px;
  -webkit-flex-direction: column;
          flex-direction: column;
  cursor: default;
}

.public-DraftEditor-content {
  width: 100%;
}

div[data-contents="true"] * {
  width: 100%;
}

.editor-buttons-div {
  width: 100%;
  margin-bottom: 1em;
  padding-bottom: 1em;
  border-bottom: 1px solid #ccc;
}

.editor-buttons-div button {
  border: none;
  padding: .8em;
  margin-right: 1em;
  background-color: rgba(200,200,200,.1);
  outline: none;
  cursor: pointer;
  color: black;
}

.editor-buttons-div button:active {
  background-color: rgba(150,150,150,.1);
}

.editor-div {
  border: 1px solid #ccc;
  padding: 1em;
  display: block;
  margin-bottom: 1em;
  background-color: white;
}

.grab {
  cursor: -webkit-grab;
  cursor: grab;
  margin-bottom: 3em;
}

.grabbing {
  pointer-events: auto !important;
  cursor: grabbing !important;
  cursor: -webkit-grabbing !important;
}

.clickable {
  cursor: pointer;
}

.padded-horizontal {
  padding-left: 2em;
  padding-right: 2em;
}

/*************** LAYOUT *****************/
.main {
  margin-top: 2em;
  display: -webkit-flex;
  display: flex;
  -webkit-flex: 1 1;
          flex: 1 1;
  padding: 3em;
  /* border: 2px solid #eee; */
}

.nav {
  position: absolute;
  top: 1em;
  right: 1em;
}

.nav .button {
  margin-left: 1.5em;
}

ul {
  margin-left: -2em;
}

.subtle-distinct {
  background: rgba(240,240,240,.4);
  padding: 1em;
  margin-top: 1em;
  margin-bottom: 1em;
}

